import React from 'react'

export default function Snowflake() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 6.82666 6.82666">
 <defs>
 </defs>
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_264006352">
   <polygon id="_264007864"  points="3.77039,2.54075 3.85235,3.00038 4.30253,3.10095 4.51217,3.14778 4.34769,3.28634 3.9905,3.58723 4.1284,4.02709 4.19262,4.23194 3.99082,4.15893 3.55131,3.99992 3.23952,4.33907 3.09431,4.49701 3.05667,4.28591 2.97471,3.82628 2.52453,3.72571 2.31489,3.67888 2.47937,3.54032 2.83656,3.23943 2.69866,2.79957 2.63444,2.59472 2.83624,2.66773 3.27575,2.82674 3.58754,2.48759 3.73276,2.32965 "/>
   <polygon id="_264008176"  points="3.21836,5.25011 2.99318,4.83607 2.57915,5.06125 2.49635,4.909 2.9865,4.64242 3.06263,4.60101 3.10403,4.67714 3.37061,5.1673 "/>
   <polygon id="_264007792"  points="3.0748,5.73601 2.84962,5.32197 2.43559,5.54715 2.35279,5.3949 2.84294,5.12831 2.91907,5.08691 2.96047,5.16304 3.22706,5.6532 "/>
   <polygon id="_264007000"  points="4.50093,1.34157 4.01076,1.60816 3.93463,1.64956 3.89323,1.57343 3.62666,1.08327 3.77891,1.00046 4.00408,1.4145 4.41812,1.18932 "/>
   <polygon id="_264006928"  points="4.35737,1.82747 3.8672,2.09406 3.79108,2.13546 3.74968,2.05933 3.4831,1.56917 3.63535,1.48636 3.86053,1.9004 4.27456,1.67522 "/>
   <path id="_264006856"  d="M4.24118 0.988469l-0.473055 1.60114 -0.204594 -0.0604488 0.473055 -1.60114 0.204594 0.0604449zm-0.97765 3.30903l-0.473059 1.60114 -0.204594 -0.0604449 0.473055 -1.60115 0.204598 0.0604488z"/>
   <polygon id="_264007384"  points="5.75153,3.31911 5.27558,3.02791 5.20169,2.9827 5.24684,2.90892 5.53804,2.43298 5.68577,2.52322 5.43971,2.92538 5.84177,3.17138 "/>
   <polygon id="_264007552"  points="5.25895,3.43773 4.783,3.14653 4.70911,3.10132 4.75426,3.02754 5.04546,2.5516 5.19319,2.64184 4.94713,3.044 5.34919,3.29 "/>
   <path id="_264007120"  d="M5.92704 2.91726l-1.62316 0.39089 -0.049315 -0.206602 1.62315 -0.390894 0.0493189 0.206606zm-3.35413 0.807965l-1.62316 0.390898 -0.0501181 -0.206843 1.62316 -0.390894 0.0501181 0.206839z"/>
   <polygon id="_264007336"  points="1.23272,4.28143 1.47878,3.87926 1.07672,3.63327 1.16696,3.48554 1.64291,3.77674 1.7168,3.82194 1.67165,3.89572 1.38045,4.37167 "/>
   <polygon id="_264007456"  points="1.7253,4.1628 1.97137,3.76064 1.5693,3.51465 1.65954,3.36691 2.13549,3.65811 2.20938,3.70332 2.16424,3.7771 1.87303,4.25304 "/>
   <polygon id="_264007216"  points="4.65548,5.36665 4.66969,4.80887 4.67189,4.7224 4.75848,4.72461 5.31627,4.73883 5.31174,4.91188 4.84054,4.89987 4.82853,5.37119 "/>
   <polygon id="_264007408"  points="4.30646,4.99938 4.32067,4.44159 4.32287,4.35513 4.40946,4.35733 4.96724,4.37156 4.96271,4.54461 4.49152,4.53259 4.47951,5.00391 "/>
   <path id="_264006808"  d="M5.09113 5.31804l-1.14851 -1.20857 0.154476 -0.146394 1.14851 1.20857 -0.15448 0.146394zm-2.34596 -2.46895l-1.18062 -1.24237 0.153677 -0.14663 1.18062 1.24237 -0.153677 0.14663z"/>
   <polygon id="_264006712"  points="1.5628,1.93454 2.034,1.94654 2.046,1.47523 2.21906,1.47976 2.20484,2.03754 2.20264,2.12401 2.11605,2.1218 1.55827,2.10759 "/>
   <polygon id="_264006616"  points="1.91183,2.30181 2.38302,2.31382 2.39502,1.8425 2.56807,1.84704 2.55387,2.40482 2.55167,2.49129 2.46507,2.48908 1.90729,2.47487 "/>
  </g>
 </g>
</svg>
  )
}

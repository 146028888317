import React from "react";

export default function Sun() {
  return (
    <svg
      version="1.1"
      id="sun"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="16 16 96 96"
    >
      <g id="row1">
        <path
          id="icon:4_1_"
          fill="#000"
          d="M44.347,64.065c0-10.854,8.799-19.653,19.653-19.653s19.653,8.799,19.653,19.653
		S74.854,83.717,64,83.717S44.347,74.918,44.347,64.065z M65,85.786h-2V96h2V85.786z M87.334,85.92l-6.49-6.491l-1.414,1.414
		l6.49,6.491L87.334,85.92z M49.302,80.112l-1.414-1.414l-7.222,7.223l1.414,1.414L49.302,80.112z M96,63h-9.18v2H96V63z M42.214,63
		H32v2h10.214V63z M49.302,47.888l-7.222-7.223l-1.414,1.414l7.222,7.223L49.302,47.888z M87.334,42.08l-1.414-1.414l-6.49,6.491
		l1.414,1.414L87.334,42.08z M65,32h-2v9.18h2V32z"
        />
      </g>
    </svg>
  );
}

import React from "react";

export default function Fan({ status }) {
  const st = status.split(" ")[0]
  let level = [
    'grey',
    'grey',
    'grey',
    'none'
  ];
  if(st == "5" || st == "0") level = [
    'black',
    'black',
    'black',
    'red'
  ]
  else if(st == "4") level = [
    'black',
    'grey',
    'grey',
    'none'
  ]
  else if(st == "2") level = [
    'black',
    'black',
    'grey',
    'none'
  ]
  else if(st == "1") level = [
    'black',
    'black',
    'black',
    'none'
  ]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      style={{position:"relative"}}
    >
      <g>
        <path
          fill={level[0]}
          stroke={level[0]}
          d="M35.318,16.847c-0.23,0.108-0.409,0.279-0.528,0.483c-0.189,0.326-0.221,0.735-0.049,1.102   c0.277,0.596,0.987,0.857,1.585,0.579c9.478-4.407,20.215-4.256,29.367,0.025c-0.349-1.09-0.785-2.138-1.297-3.141   C55.148,12.31,44.661,12.504,35.318,16.847z"
        />
        <path fill={level[0]} d="M23.056,25.495c7.406,3.942,12.449,8.376,16.067,12.564c1.291-1.284,2.809-2.385,4.552-3.196   c6.891-3.204,14.874-1.103,19.402,4.595c3.911-6.787,4.6-14.222,2.615-20.421c-9.152-4.281-19.89-4.432-29.367-0.025   c-0.597,0.277-1.308,0.017-1.585-0.579c-0.172-0.367-0.139-0.777,0.049-1.102c0.119-0.205,0.298-0.375,0.528-0.483   c9.343-4.343,19.83-4.537,29.078-0.951c-1.764-3.456-4.476-6.33-8.022-8.211c12.666,1.592,24.417,8.763,31.475,20.401   c0.341,0.564,1.075,0.743,1.638,0.401c0.564-0.339,0.743-1.075,0.401-1.638C81.781,13.478,67.811,5.588,53.102,5.036   C34.782,4.349,24.693,13.7,22.514,16.174C19.453,19.648,18.728,23.197,23.056,25.495z" />
        
        <path
          fill={level[1]}
          stroke={level[1]}
          d="M86.291,52.618c0.021-0.253-0.039-0.493-0.157-0.698c-0.189-0.326-0.528-0.557-0.932-0.591   c-0.655-0.057-1.234,0.431-1.29,1.087c-0.886,10.414-6.354,19.657-14.618,25.471c1.119,0.239,2.246,0.382,3.37,0.436   C80.371,72.08,85.416,62.884,86.291,52.618z"
        />
        <path fill={level[1]} d="M93.239,41.842c-1.493-4.383-4.213-6.776-8.358-4.163c-7.102,4.468-13.456,6.639-18.888,7.697   c0.472,1.759,0.674,3.623,0.511,5.539c-0.645,7.571-6.436,13.455-13.631,14.552c3.946,6.767,10.055,11.06,16.42,12.419   c8.264-5.813,13.731-15.056,14.618-25.471c0.056-0.656,0.635-1.144,1.29-1.087c0.404,0.033,0.743,0.265,0.932,0.591   c0.119,0.205,0.178,0.445,0.157,0.698c-0.875,10.266-5.919,19.462-13.627,25.705c3.875,0.187,7.718-0.738,11.112-2.88   C76.1,85.642,64.036,92.275,50.43,92.616c-0.659,0.016-1.179,0.563-1.162,1.222c0.014,0.658,0.563,1.179,1.222,1.162   c15.632-0.39,29.422-8.591,37.211-21.08C97.402,58.365,94.302,44.962,93.239,41.842z" />
        
        <path
          fill={level[2]}
          stroke={level[2]}
          d="M29.764,78.963c0.208,0.145,0.446,0.214,0.683,0.214c0.376,0,0.747-0.177,0.979-0.51   c0.377-0.538,0.246-1.284-0.294-1.661c-8.565-5.99-13.82-15.355-14.705-25.42c-0.768,0.849-1.456,1.751-2.068,2.697   C15.894,64.082,21.321,73.058,29.764,78.963z"
        />
        <path fill={level[2]} d="M41.16,62.698c-6.227-4.355-8.413-12.316-5.754-19.09c-7.833,0.02-14.612,3.152-18.979,7.978   c0.885,10.065,6.14,19.43,14.705,25.42c0.539,0.378,0.671,1.123,0.294,1.661c-0.232,0.333-0.602,0.51-0.979,0.51   c-0.237,0-0.474-0.07-0.683-0.214c-8.443-5.906-13.869-14.882-15.405-24.681c-2.105,3.259-3.232,7.047-3.082,11.058   c-4.974-11.757-4.662-25.52,1.867-37.462c0.317-0.578,0.104-1.303-0.474-1.618c-0.577-0.318-1.303-0.104-1.618,0.474   C3.549,40.454,3.728,56.498,10.627,69.5c8.592,16.194,21.742,20.234,24.975,20.879c4.541,0.906,7.975-0.246,7.793-5.144   c-0.303-8.385,1.004-14.971,2.813-20.201C44.45,64.561,42.736,63.8,41.16,62.698z" />
       
        <ellipse
        fill={level[0]}
          transform="matrix(0.9075 -0.4201 0.4201 0.9075 -16.1523 25.7309)"
          cx="50.343"
          cy="49.538"
          rx="11.524"
          ry="11.524"
        />
      </g>
      <text
        x="70"
        y="35"
        fill={level[3]}
        fontSize="45px"
        style={{position: "absolute", top:"0px", bottom:"0px", left:"0px", right:"0px"}}
      >
        A
      </text>
    </svg>
  );
}

import React from 'react'
import Fan from '../assets/icons/Fan';
import Sun from '../assets/icons/Sun';
import Snowflake from '../assets/icons/Snowflake';

export default function Mode({modeStatus}) {
    const mode = modeStatus.split(" ")[1]

    if(mode === '(Fan)'){
        return (
            <Fan status="1 ()"/>
        )
    }else if(mode === '(Heat)'){
        return (
            <Sun />
        )
    }else if(mode === '(Cool)'){
        return (
            <Snowflake /> 
        )
    }
}

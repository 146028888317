import React, { useState, useEffect } from "react";
import axios from "../axios";
import Power from "../assets/icons/Power";
import Disk from "../assets/icons/Disk";
import Mode from "./Mode";
import Fan from "../assets/icons/Fan";
import Thermometer from "../assets/icons/Thermometer";
import moment from "moment";
import ClockCalendar from "../assets/icons/ClockCalendar";

const Home = () => {
  let [records, setRecords] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      getTheData();
    }, 3000);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  useEffect(() => {
    getTheData();
  }, []);

  const getTheData = () => {
    axios
      .get(`get_all_air_conditioner_records`)
      .then((res) => {
        setRecords(res.data);
        console.log(res.data);
      })
      .catch((err) => {});
  };

  const deleteData = () => {
    axios
      .delete(`delete_all_air_conditioner_records`)
      .then((res) => {
        setRecords([]);
      })
      .catch((err) => {});
  };

  return (
    <section className="py-12">
      <div className="m-4 mb-16 flex justify-center">
        <button className="bg-red-600 py-2 px-4 rounded font-bold text-white mx-auto" onClick={deleteData}>Delete All Data</button>
      </div>
      {records.map((rec) => (
        <div
          key={rec.id}
          className="bg-white odd:bg-gray-100 p-2">
          <div
            className="grid grid-cols-3 gap-4 mb-8 lg:w-1/4 md:w-5/6 mx-auto"
          >
            <div>
              <div className="h-[35px] w-[55px] mb-3 p-2 text-center mx-auto border-b-2 border-black">
                POW
              </div>
              {rec.power && (
                <div className="h-[35px] w-[35px] text-center mx-auto">
                  <Power status={rec.power} />
                </div>
              )}
            </div>

            <div>
              <div className="h-[35px] w-[55px] mb-3 py-2 px-0 text-center mx-auto border-b-2 border-black">
                BUZZER
              </div>
              {rec.light && (
                <div className="h-[35px] w-[35px] text-center mx-auto">
                  <Disk status={rec.light} />
                </div>
              )}
            </div>

            <div>
              <div className="h-[35px] w-[55px] mb-3 py-2 px-0 text-center mx-auto border-b-2 border-black">
                MODE
              </div>
              {rec.mode && (
                <div className="h-[35px] w-[35px] text-center mx-auto">
                  <Mode modeStatus={rec.mode} />
                </div>
              )}
            </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mb-8 lg:w-1/4 md:w-5/6 mx-auto">
            <div>
              <div className="h-[35px] w-[55px] mb-3 py-2 px-0text-center mx-auto border-b-2 border-black text-center">
                SWING
              </div>
              {rec.swing && (
                <div className="h-[35px] w-[35px] text-center mx-auto">
                  <Disk status={rec.swing} />
                </div>
              )}
            </div>

            <div>
              <div className="h-[35px] w-[55px] mb-3 p-1 text-center mx-auto border-b-2 border-black">
                FAN
              </div>
              {rec.fan && (
                <div className="h-[35px] w-[35px] text-center mx-auto">
                  <Fan status={rec.fan} />
                </div>
              )}
            </div>

            <div>
              <div className="h-[35px] w-[55px] mb-3 p-2 text-center text-center mx-auto border-b-2 border-black">
                TEMP
              </div>
              {rec.temp && (
                <div className="h-[35px] w-[35px] text-center mx-auto">
                  {rec.temp}
                </div>
              )}
            </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-8 lg:w-1/4 md:w-5/6 mx-auto">
            <div>
              <div className="h-[35px] w-[125px] mb-3 p-2 text-center mx-auto border-b-2 border-black">
                DATE TIME
              </div>
              <div className="text-center">
                {moment(rec.createdAt).format("YYYY-MM-DD HH:mm:SS")}
              </div>
            </div>
            </div>
          </div>
      ))}
    </section>
  );
};

export default Home;
